<template>
  <div>
    <ds-row>
      <ds-col>
        <ds-heading size="md" text="Ative a autenticação de dois fatores" />
        <ds-p>
          A autenticação de dois fatores é uma camada extra de segurança que ajuda a proteger sua conta contra acessos
          não autorizados.
          <ds-external-link
            href="https://ajuda.contaazul.com/hc/pt-br/articles/29302562127117-Conta-Azul-Pro-acesso-com-autentica%C3%A7%C3%A3o-de-dois-fatores#h_01JBVTTYC0YT5HN6BR486E2W6Y">
            Saiba mais.
          </ds-external-link>
        </ds-p>
      </ds-col>
    </ds-row>
    <ds-async-content-wrapper :fetch-action="fetchAction" @fetch-success="onFetchSuccess">
      <ds-row>
        <ds-col>
          <ds-box-information theme="warning" hide-close-button>
            <div class="ds-u-display--flex ds-u-align-items--center ds-u-height--full">
              Esta configuração é obrigatória para sua conta.
            </div>
          </ds-box-information>
        </ds-col>
      </ds-row>
      <ds-form
        :is-valid.sync="isFormValid"
        :submit-action="validateCode"
        @submit-success="onSuccessValidate"
        @submit-error="onErrorValidate">
        <ds-list theme="number-circle">
          <ds-list-item class="ds-u-display--flex ds-u-margin-top--xl">
            <div class="ds-list-item-content">
              <ds-text weight="bold">Acesse ou instale um aplicativo de autenticação.</ds-text>
              Recomendamos o
              <ds-text weight="bold">Google Authenticator.</ds-text>
              &nbsp;
              <ds-link ref="popover-link" @mouseenter="openPopover">
                <div class="ds-u-display--flex ds-u-align-items--center">
                  <ds-icon icon="question-circle" color="blue" size="sm" />
                  &nbsp;Como instalar?
                </div>
              </ds-link>
            </div>
          </ds-list-item>
          <ds-list-item class="ds-u-display--flex ds-u-margin-top--xl">
            <div class="ds-list-item-content">
              <ds-text weight="bold">No seu aplicativo de autenticação,</ds-text> faça a leitura do QR code ou insira o
              código abaixo.
              <ds-row justify="center" class="ds-u-margin-top--sm">
                <ds-col size="auto">
                  <ds-qrcode :value="key.qrCode" />
                </ds-col>
              </ds-row>
              <ds-row justify="center" class="ds-u-margin-bottom--none ds-u-align-items--center">
                <ds-col size="fill">
                  <ds-p class="secret-key-break">
                    {{ key.secret }}
                  </ds-p>
                </ds-col>
                <ds-col size="auto">
                  <ds-link v-ds-tooltip="'Copiar código'" @click="onCopyRequest">
                    <ds-icon :icon="['regular', 'copy']" color="blue" />
                  </ds-link>
                </ds-col>
              </ds-row>
            </div>
          </ds-list-item>
          <ds-list-item class="ds-u-display--flex ds-u-margin-top--xl">
            <div class="ds-list-item-content">
              Insira o <ds-text weight="bold">código de 6 dígitos</ds-text> gerado pelo seu aplicativo de autenticação.
              <ds-row class="ds-u-align-items--flex-end ds-u-margin-top">
                <ds-col size="auto">
                  <ds-field label="Código" class="ds-u-margin-bottom--none">
                    <ds-input
                      v-model="verificationCode"
                      :custom-validations="customValidations"
                      maxlength="6"
                      required />
                  </ds-field>
                </ds-col>
                <ds-col size="auto">
                  <ds-submit-button theme="primary" :disabled="!isFormValid">Autenticar</ds-submit-button>
                </ds-col>
              </ds-row>
            </div>
          </ds-list-item>
        </ds-list>
      </ds-form>
      <ds-row class="ds-u-margin-top">
        <ds-col class="ds-u-justify-content--center ds-u-display--flex">
          <ds-external-link
            with-icon
            href="https://ajuda.contaazul.com/hc/pt-br/articles/29302562127117-Conta-Azul-Pro-acesso-com-autentica%C3%A7%C3%A3o-de-dois-fatores">
            Acessar central de ajuda
          </ds-external-link>
        </ds-col>
      </ds-row>
    </ds-async-content-wrapper>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard';
import { sanitizeUrl } from '@contaazul/sanitize-url';
import { toasterService } from '@contaazul/design-system';
import { verifyUserAndSetupTotp } from '@/services/amplifyService';
import { confirmTotpToken } from '@/services/confirmTotpService';
import {
  triedToSetupTotpTrack,
  errorSetupTotpTrack,
  successSetupTotpTrack,
  triedToValidateTotpCodeTrack,
} from '@/services/authenticationTrack/authenticationTrackService';
import GoogleAuthenticatorPopover from './GoogleAuthenticatorPopover.vue';

export default {
  name: 'MfaTotpConfiguration',
  data() {
    return {
      isFormValid: null,
      redirect: '',
      email: null,
      user: null,
      key: {
        secret: '',
        qrCode: '',
      },
      verificationCode: '',
      customValidations: [
        {
          message: 'O código precisa conter 6 digitos',
          valid(value) {
            return value.length === 6;
          },
        },
      ],
      popoverInstance: null,
    };
  },
  mounted() {
    const { redirect = '' } = this.$route.query;
    this.redirect = redirect;
  },
  methods: {
    openPopover() {
      if (!this.popoverInstance) {
        this.popoverInstance = this.$dsPopover(GoogleAuthenticatorPopover, {
          props: {
            onPopoverClose: this.onPopoverClose,
          },
        }).open(this.$refs['popover-link'].$el, {
          placement: 'bottom-end',
        });
      }
    },
    onPopoverClose() {
      this.popoverInstance = null;
    },
    fetchAction() {
      const { email } = this.$route.params;
      this.email = email;
      triedToSetupTotpTrack({
        email: this.email,
      });
      return verifyUserAndSetupTotp();
    },
    onFetchSuccess({ user, secret }) {
      if (!user || !secret) {
        errorSetupTotpTrack({
          email: this.email,
          properties: {
            step: !user ? 'errorOnGetUser' : 'errorOnGetSecret',
          },
        });
        this.$router.push({ name: 'Login' });
        return;
      }
      this.user = user;

      successSetupTotpTrack({
        email: this.user.username,
      });
      const qrCode = `otpauth://totp/ContaAzul:${user.username}?secret=${secret}&issuer=ContaAzul`;
      this.key = {
        secret,
        qrCode,
      };
    },
    onCopyRequest() {
      if (copy(this.key.secret)) {
        this.onCopySuccess();
      } else {
        this.onCopyError();
      }
    },
    onCopySuccess() {
      toasterService.success({
        content: 'Copiado para a área de transferência!',
      });
    },
    onCopyError() {
      toasterService.warning({
        title: 'Erro ao copiar o QR code',
        content: 'Necessário permitir esta ação nas configurações do seu navegador.',
      });
    },
    validateCode() {
      triedToValidateTotpCodeTrack({
        email: this.user.username,
      });
      return confirmTotpToken(this.verificationCode, this.user);
    },
    onSuccessValidate(data) {
      if (this.redirect) {
        window.location.href = sanitizeUrl(this.redirect);
        return;
      }
      window.location.href = sanitizeUrl(data.location);
    },
    onErrorValidate(error) {
      toasterService.error({ title: error.title, content: error.detail });
    },
  },
};
</script>

<style scoped>
.ds-list-item-content {
  flex: 1;
}
.secret-key-break {
  line-break: anywhere;
}
</style>
