import { render, staticRenderFns } from "./MfaTotpConfiguration.vue?vue&type=template&id=21e2dd2a&scoped=true"
import script from "./MfaTotpConfiguration.vue?vue&type=script&lang=js"
export * from "./MfaTotpConfiguration.vue?vue&type=script&lang=js"
import style0 from "./MfaTotpConfiguration.vue?vue&type=style&index=0&id=21e2dd2a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e2dd2a",
  null
  
)

export default component.exports