<template>
  <ds-popover ref="popover" @hook:destroyed="onPopoverClose">
    <ds-row>
      <ds-col size="fill">
        <ds-heading size="sm" text="Instale o Google Authenticator" />
        <ds-p class="p-width-328">
          Aponte a câmera do seu celular para o QR Code abaixo para instalar o aplicativo.
        </ds-p>
        <div class="ds-u-margin-top ds-u-display--flex ds-u-justify-content--center">
          <ds-qrcode value="https://onelink.to/84ngah" />
        </div>
      </ds-col>
      <ds-col size="auto">
        <ds-button
          :icon="['light', 'times']"
          theme="link"
          size="xl"
          class="ds-u-color--grey-dark close-button"
          @click="closePopover" />
      </ds-col>
    </ds-row>
  </ds-popover>
</template>

<script>
export default {
  name: 'GoogleAuthenticatorPopover',
  props: {
    onPopoverClose: {
      type: Function,
      required: true,
    },
  },
  methods: {
    closePopover() {
      this.$refs.popover.close();
    },
  },
};
</script>

<style scoped>
.p-width-328 {
  width: 328px;
}
.close-button {
  margin: -16px;
}
</style>
