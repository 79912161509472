import { hasCaProWeb } from './caProWebAbTestService';

const getOriginURL = async (route, email) => {
  const urlFromQuery = getURLFromQuery(route);
  if (urlFromQuery) {
    return urlFromQuery;
  }
  const urlFromEnv = await getURLFromEnv(email);
  return urlFromEnv;
};

function getURLFromQuery(route) {
  return route.query && route.query.next_origin;
}

function isContaAzulLogin(email) {
  return email?.toLowerCase().endsWith('@contaazul.com');
}

async function getURLFromEnv(email) {
  if (isContaAzulLogin(email)) {
    return process.env.VUE_APP_STANDALONE_URL;
  }

  const isCaProWeb = await hasCaProWeb(email);
  return isCaProWeb ? process.env.VUE_APP_STANDALONE_URL : process.env.VUE_APP_ORIGIN_URL;
}

export default getOriginURL;
