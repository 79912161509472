<template>
  <div class="ds-u-padding-x--md">
    <ds-row>
      <ds-col>
        <alert-messenger :messages-model="alertMessages" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <ds-heading text="Informe seus dados abaixo" />
      </ds-col>
    </ds-row>
    <ds-row>
      <ds-col>
        <login-form />
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import alertMessages from '@/components/login/alertMessages';
import AlertMessenger from '@/components/alertMessenger/AlertMessenger';
import LoginForm from '@/components/loginForm/LoginForm';

export default {
  name: 'Login',
  components: {
    LoginForm,
    AlertMessenger,
  },
  data() {
    return {
      alertMessages,
    };
  },
};
</script>
